<template>
	<div id="app">
		<el-container>
			<el-header style="height: 100px;">
				<div class="logo">
					<img src="./assets/img/toplogo.jpg" @click="homepage" />
				</div>
				<div class="bannerRight" v-if="isFirsPage">
					<div class="codeBox">
						<div id="qrcode" title="班主任手机扫码登录班级网页!"></div>
					</div>
					<div class="FPLog">
						<el-form ref="lform" :model="loginform" :rules="rules" label-width="10px">
							<!-- 第一行 -->
							<el-form-item>
								<div class="form-row">
									<img src="./assets/img/ane_user_1.png" alt="placeholder" class="image" />
									<el-input name="name" v-model="loginform.name" placeholder="请输入用户名"
										style="width:150px"></el-input>
									<el-button type="primary" @click="login">登录</el-button>
								</div>
							</el-form-item>

							<!-- 第二行 -->
							<el-form-item>
								<div class="form-row">
									<img src="./assets/img/ane_pwd_2.png" alt="placeholder" class="image" />
									<el-input name="password" type="password" v-model="loginform.pass"
										placeholder="请输入密码" autocomplete="off" style="width:150px"></el-input>
									<el-button type="danger" @click="logout2">登出</el-button>
								</div>
							</el-form-item>
						</el-form>
					</div>
				</div>
				<div class="searchBox" v-if="isLoggedIn">
					<el-row type="flex" justify="start">
						<el-col :span="15">
							<el-input v-model="searchStr" placeholder="输入学生姓名查询信息"></el-input>
						</el-col>
						<el-col :span="4" style="padding-left: 2px;">
							<el-button type="primary" icon="el-icon-search" @click="handleSearch">查找</el-button>
						</el-col>
					</el-row>
				</div>
			</el-header>
			<el-main>
				<router-view />
			</el-main>
			<div class="footer">
				<span>CopyRight © 德育云信息服务版权所有 咨询电话:157 0677 0996 </span>
				<a href="https://beian.miit.gov.cn/" target="_blank" style="cursor:pointer">备案号:浙ICP备2023020133号-1</a>
			</div>
		</el-container>
	</div>
</template>

<script>
	import {
		md5
	} from './js/JSMD5.js';
	import QRCode from 'qrcodejs2';
	export default {
		data() {
			return {
				IfClass: true,
				ws: null,
				message: '',
				router: true,
				defaultActive: "",
				isLoggedIn: false,
				isFirsPage: true,
				username: sessionStorage.getItem("UserName"),
				searchStr: '',
				loginform: {
					name: "",
					pass: ""
				},
				rules: {
					name: [{
						required: true,
						message: "请输入用户名",
						trigger: "blur"
					}],
					pass: [{
							required: true,
							message: "请输入密码",
							trigger: "blur"
						},
						{
							min: 5,
							max: 20,
							message: "密码长度在 5 到 20 个字符",
							trigger: "blur"
						}
					]
				}
			};
		},
		mounted() {
			this.checkClassLogin();
			this.checkClass();
		},
		methods: {
			checkClass() {
				const classLimit = sessionStorage.getItem('UserLimit');
				if (classLimit == '8') {
					this.IfClass = false;
				}
			},
			createQRcode(clientId) {
				if (document.getElementById("qrcode")) {
					new QRCode(document.getElementById("qrcode"), {
						text: clientId,
						width: 88,
						height: 88,
						colorDark: "#00aa00",
						colorLight: "#ffffff",
						correctLevel: QRCode.CorrectLevel.H
					});
				}
			},
			checkClassLogin() {
				const ClassInfo = localStorage.getItem('localClassInfo');
				const sessionClassInfo = JSON.parse(ClassInfo);
				if (sessionClassInfo) {
					if (sessionClassInfo.Headteacher) {
						this.defaultClassSession(sessionClassInfo);
					} else {
						console.log('没有班级信息');
					}
				} else {
					this.handleTableMounted();
				}
			},
			handleTableMounted() {
				this.$nextTick(() => {
					this.connect();
				});
			},
			connect() {
				this.ws = new WebSocket('wss://wzdyy.com:8088');
				this.ws.onopen = () => {
					console.log('WebSocket连接已打开');
					this.connectWebSocket(); // 在连接打开后发送消息
				};
				this.ws.onmessage = (event) => { // 接收到的消息是event.data
					this.message = event.data;
					const connectMessage = JSON.parse(event.data);
					console.log(connectMessage);
					if (connectMessage.statue == 'handshake') {
						console.log('服务器握手成功收到邀请码:' + connectMessage.clientId);
						this.createQRcode(connectMessage.clientId);
					} else if (connectMessage.statue == 'moralLogin') {
						console.log('收到服务器推送的信息:', connectMessage);
						localStorage.setItem("localClassInfo", JSON.stringify(connectMessage)); // 班主任信息保存本地电脑
						this.defaultClassSession(connectMessage);
						if (connectMessage.Headteacher) {
							this.$message({
								message: "扫码成功，将跳到班级网页!",
								type: "success"
							});
						} else {
							this.$message({
								message: "没有班主任信息，无法登录！",
								type: "fail"
							});
						}
					} else {
						console.log('收到服务器推送的其他信息:' + connectMessage);
					}
				};
				this.ws.onerror = (error) => {
					console.error('WebSocket发生错误:', error);
				};
				this.ws.onclose = () => {
					console.log('WebSocket连接已关闭');
					// 你可以在这里尝试重新连接
				};
			},
			defaultClassSession(ui) {
				const {
					currentWeek,
					currentDay,
					currentWeekDay
				} = this.getCurrentWeekAndDay(ui.StartDate, ui.StartWeek)
				sessionStorage.setItem("token", ui.toClientId); // 用户类型
				sessionStorage.setItem("ClassCode", ui.Headteacher); // 保存当前周次
				sessionStorage.setItem("ClassName", ui.ClassName); // 保存当前周次
				sessionStorage.setItem("currentWeek", currentWeek); // 保存当前周次
				sessionStorage.setItem("currentWeekDay", currentWeekDay); // 保存当前日期-星期几
				sessionStorage.setItem("currentDay", currentDay); // 保存当前周次
				sessionStorage.setItem("Term", ui.Term); // 保存当前学期
				sessionStorage.setItem("Year", ui.Year); // 保存当前学年
				sessionStorage.setItem("UserName", ui.UserName); // 保存用户名
				sessionStorage.setItem("UserId", ui.UserID); // 保存学校代码
				sessionStorage.setItem("SchoolCode", ui.SchoolCode); // 保存学校代码
				sessionStorage.setItem("SchoolName", ui.SchoolName); // 保存学校代码
				sessionStorage.setItem("UserLimit", '8'); // 用户类型
				sessionStorage.setItem("StartDate", ui.StartDate); // 保存起始时间
				this.$router.push({
					name: 'class_index',
					path: '/admin/Class/class_index',
				});
			},
			connectWebSocket() {
				if (this.ws.readyState === WebSocket.OPEN) {
					const urlData = {
						statue: 'handshake'
					};
					const connectMessage = JSON.stringify(urlData);
					this.ws.send(connectMessage);
				} else {
					console.log('WebSocket连接未打开');
				}
			},
			beforeDestroy() {
				// 组件销毁前关闭WebSocket连接
				if (this.ws) {
					this.ws.close();
					console.log('WebSocket关闭成功!');
				} else {
					console.log('WebSocket关闭状态!');
				}

			},
			homepage() {
				this.$router.push({
					name: 'default',
					path: '/admin/default',
				});
			},
			logout() {
				// 清除所有的会话数据
				sessionStorage.clear();
				this.$LoginName.value = '';
				//重定向到登录页面
				this.$router.push('/login');

			},
			logout2() {
				// 清除所有的会话数据
				sessionStorage.clear();
				this.$LoginName.value = '';
				this.$message({
					message: "登录信息凭证已清除",
					type: "success"
				});

			},
			async handleSearch() {
				this.$router.push({
					path: '/admin/studentSearchResult',
					query: {
						studentName: this.searchStr,
					}
				});
			},
			login() {
				this.$refs.lform.validate(valid => {
					if (valid) {
						// 构造请求体数据
						const formData = {
							username: this.loginform.name,
							password: md5.hexMD5(this.loginform.pass),
						};

						// 发起 POST 请求
						this.$http.post('/Login', formData)
							.then(response => {
								window.console.log(response);
								if (response.data.success) {
									this.$message({
										message: `登录成功!欢迎您${this.loginform.name}老师！`,
										type: "success"
									});
									const {
										currentWeek,
										currentDay,
										currentWeekDay
									} = this.getCurrentWeekAndDay(response.data.schoolData.StartDate, response
										.data.schoolData.StartWeek)
									sessionStorage.setItem("token", response.data.schoolData
									.token); // 保存token字段
									sessionStorage.setItem("currentWeek", currentWeek); // 保存当前周次
									sessionStorage.setItem("currentWeekDay", currentWeekDay); // 保存当前日期-星期几
									sessionStorage.setItem("StartDate", response.data.schoolData
									.StartDate); // 保存起始时间
									sessionStorage.setItem("currentDay", currentDay); // 保存当前周次
									sessionStorage.setItem("Term", response.data.schoolData.Term); // 保存当前学期
									sessionStorage.setItem("Year", response.data.schoolData.Year); // 保存当前学年
									sessionStorage.setItem("UserName", this.loginform.name); // 保存用户名
									sessionStorage.setItem("UserId", response.data.schoolData.TIid); // 保存学校代码
									sessionStorage.setItem("SchoolCode", response.data.schoolData
									.SchoolCode); // 保存学校代码
									sessionStorage.setItem("UserLimit", response.data.schoolData
									.UserCategorys); // 用户类型
									this.$router.push('/admin/default')
									this.$LoginName.value = this.loginform.name;
								} else {
									this.$message({
										message: "账号或密码错误",
										type: "error"
									});
								}
							})
							.catch(error => {
								window.console.log(error);
								if (error.response) {
									// 后端返回了错误响应
									this.$message({
										message: "请求错误：" + error.response.data.message,
										type: "error"
									});
								} else {
									// 请求发送失败
									this.$message({
										message: "网络或程序异常：" + error.message,
										type: "error"
									});
								}
							});
					} else {
						this.$message({
							message: "请输入合法的值",
							type: "error"
						});
						return false;
					}
				});
			},
			getCurrentWeekAndDay(StartTime, Week) {
				const weekDayList = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];
				const startTimestamp = new Date(StartTime).getTime();
				const startTimestampPlus8Hours = startTimestamp + (8 * 60 * 60 * 1000); // 加上8小时
				const currentTimestamp = Date.now(); // 获取当前时间的时间戳
				const timeDiff = currentTimestamp - startTimestampPlus8Hours; // 计算时间间隔（毫秒数）
				const passedWeeks = Math.floor(timeDiff / (1000 * 60 * 60 * 24 * 7)); // 计算已过的周数（去除小数）
				const currentWeek = Week + passedWeeks; // 计算当前周次
				let currentDay = new Date().getDay(); // 星期日为0， 星期一为1，...，星期六为6
				let currentWeekDay = weekDayList[currentDay];
				return {
					currentWeek,
					currentDay,
					currentWeekDay
				};
			},
		},
		watch: {
			//点击登录按钮的消失与出现
			$route(to, from) {
				if (to.path.startsWith('/admin')) {
					this.isLoggedIn = true && this.IfClass;
				} else {
					this.isLoggedIn = false;
				}
				if (to.path === '/') {
					this.isFirsPage = true;
				} else {
					this.isFirsPage = false;
				}
			},
			'$LoginName.value'(newVal) {
				this.username = newVal

			},
		},

	};
	const debounce = (fn, delay) => {
		let timer = null;
		return function() {
			let context = this;
			let args = arguments;
			clearTimeout(timer);
			timer = setTimeout(function() {
				fn.apply(context, args);
			}, delay);
		};
	};
	// 解决ERROR ResizeObserver loop completed with undelivered notifications.
	const _ResizeObserver = window.ResizeObserver;
	window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
		constructor(callback) {
			callback = debounce(callback, 16);
			super(callback);
		}
	};
</script>

<style lang="scss">
	* {
		padding: 0;
		margin: 0;
	}

	html,
	body {
		height: 100%;
	}

	#app {
		font-family: "Avenir", Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		color: #2c3e50;
	}

	.el-header {
		display: flex;
		justify-content: space-between;
		align-content: center;
		width: 100%;
		margin: 0 auto;

		.logo {
			padding: 10px 0px;

			img {
				width: 100%;
				line-height: 60px;
			}
		}

		.Login {
			margin-top: 18px;
		}
	}

	.el-main {
		padding: 0 !important;
	}

	.footer {
		font-size: 20px;
		font-weight: 200;
		color: #333;
		text-align: center;
		background-color: rgb(1, 125, 163);
		height: 60px;
		line-height: 60px;
		color: white;


		a:visited {
			color: #ffffff;
			text-decoration: none;
		}

		a:hover {
			text-decoration: none;
		}

		a {
			text-decoration: none;
		}
	}

	.searchBox {
		padding-top: 25px;

	}


	.FPLog {
		padding: 0px;
		margin: 0px;

		.form-row {
			display: flex;
			align-items: center;
			margin: 5px 0px;
		}


		.el-input {
			flex: 1;
			margin-right: 5px;
		}

		.el-button {
			margin-left: 5px;
		}

		.el-form-item {
			margin: 0px;
		}

		image {
			width: 40px;
			height: 40px;
		}
	}

	.QRcode {
		margin-right: 10px;
		width: 30%;
		height: auto;
	}

	.codeBox {
		display: flex;
		justify-content: flex-end;
		/* 添加这一行以实现右对齐 */
		/* 如果需要，还可以添加align-items来控制交叉轴上的对齐方式 */
		align-items: center;
		/* 例如，使子元素在交叉轴上居中对齐 */
	}

	.bannerRight {
		display: flex;
	}
</style>